import React from "react";
import Hero from "../../components/Hero";
import OurProducts from "../../components/OurProducts";
import CopyrightComponent from "../../components/Copyright";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import OurService from "../../components/OurService";
import BrandSection from "../../components/BrandSection";
import AboutUs from "../../components/AboutUs";
import ChatComponent from "../../components/ChatComponent";
import Contact from "../../components/Contact";

const Landing = () => {
  return (
    <>
      <Header />
      <Hero />
      <OurService />
      <BrandSection />
      <AboutUs />
      <Contact />
      <Footer />
      <CopyrightComponent />
      <ChatComponent />
    </>
  );
};

export default Landing;
