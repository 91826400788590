import React from "react";

import "./styles.css";

import HeroSlider from "../HeroSlider";

const Hero = () => {
  const menuItems = [
    { label: "PPE", link: "#" },
    { label: "Electrical Safety", link: "#" },
    { label: "Respiratory", link: "#resp" },
    { label: "Fall Protection", link: "#" },
    { label: "Lifeline", link: "#" },
    { label: "Fire", link: "#fire" },
    { label: "Gas", link: "#" },
    { label: "BMS", link: "#" },
  ];

  return (
    <>
      <div className="hero">
        <HeroSlider />
      </div>
    </>
  );
};

export default Hero;
