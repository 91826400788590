import React from "react";
import "./styles.css";
const AboutUs = () => {
  return (
    <div className="container about_us_section mb-5" id="about">
      <div className="section_title">About Us</div>
      <h4 className="text-center mb-5">Wholesale safety equipment supplier</h4>
      <div className="row">
        <div className="col-md-6">
          <iframe
            className="about_trailer"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/D4S_PrhgGkY?si=H4eKC2fn2w7lsVhu"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <p>
            Reliable Engineering Global Pte Ltd. is a Southeast Asian company
            specializing in providing Personal Protection Equipment (PPE),
            Height Safety Products, Building Management System Products, Gas &
            Fire Detection to the Government, Semi-State, Construction, Food,
            Manufacturing, and Services Industries. As a customer-oriented
            company we put emphasis on creating a transparent relationship with
            our customers and guaranteeing them the greatest possible level of
            flexibility as well as adherence to delivery dates. The vision of
            “Reliable Engineering Global Pte Ltd” is to provide high quality as
            well as long term service & cost-effective products with special
            emphasis on efficiency, safety, security, social development and
            conservation of the environment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
