import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import "./styles.css";

const FilterComponent = () => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const fetchCategories = async () => {
    try {
      setCategories([]);
      let url = `https://server.reliablepte.com/api/categories`;

      let response = await axios.get(url);

      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBrands = async () => {
    try {
      setBrands([]);
      let url = `https://server.reliablepte.com/api/brands`;

      let response = await axios.get(url);

      setBrands(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, []);

  return (
    <div className="filter-container container">
      <div className="category-menu">
        <h5></h5>
        <br />
        <br />

        <div class="list-group">
          {categories.map((element) => {
            return (
              <div>
                <p className="list_item">
                  <Link to={`${element.id}`} className="list_item_link">
                    {element.id} - {element.name} ({element.product_count})
                  </Link>
                </p>

                {element.subcategories.length > 0
                  ? element.subcategories.map((element2) => {
                      return (
                        <p className="sub_list_item">
                          <Link
                            to={`${element2.id}`}
                            className="list_item_link"
                          >
                            {element2.id} - {element2.name} (
                            {element2.product_count})
                          </Link>
                        </p>
                      );
                    })
                  : ""}
              </div>
            );
          })}
        </div>
      </div>
      <br />
      <br />
      <div className="brand-menu">
        <h5></h5>
      </div>
    </div>
  );
};

export default FilterComponent;
