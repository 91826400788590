import React, { useState } from "react";
import "./styles.css";
import logo_img from "../../assets/img/logo.png";

import { Link } from "react-router-dom";
import SearchComponent from "../SearchComponent";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (searchTerm) => {
    console.log("Searching for:", searchTerm);
    // Add your search logic here
  };

  return (
    <nav className="navbar fixed-top">
      <div className="navbar-logo">
        <a href="/" className="logo_container">
          <img src={logo_img} alt="logo" />
        </a>
      </div>

      <SearchComponent onSearch={handleSearch} />

      <div className="menu-icon" onClick={toggleMenu}>
        <div className={`line ${isOpen ? "open" : ""}`}></div>
        <div className={`line ${isOpen ? "open" : ""}`}></div>
        <div className={`line ${isOpen ? "open" : ""}`}></div>
      </div>
      <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
        <li className="has-submenu">
          <a className="parent_a" href="javascript:void(0)">
            Products
          </a>
          <ul className="nested-menu">
            <li>
              <Link to="/category/1">Fire Detection</Link>
            </li>

            <li>
              <a href="/category/3">BMS & Instruments</a>
            </li>
            <li>
              <Link to="/category/4">PPE</Link>
            </li>
            <li>
              <Link to="/category/5">Electrical Safety</Link>
            </li>
            <li>
              <Link to="/category/6">Respiratory Protection</Link>
            </li>
            <li>
              <Link to="/category/7">Fall Protection</Link>
            </li>
            <li>
              <Link to="/category/8">Gas Detection</Link>
            </li>
          </ul>
        </li>
        <li>
          <a className="parent_a" href="#services">
            Our Services
          </a>
        </li>
        <li>
          <a className="parent_a" href="#brands">
            Brands
          </a>
        </li>
        <li>
          <a className="parent_a" href="#about">
            About Us
          </a>
        </li>
        <li>
          <a className="parent_a" href="#contact">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
