import React from "react";
import Header from "../../components/Header";

import { Outlet } from "react-router-dom";
import FilterComponent from "../../components/FilterComponent";

const ProductPage = () => {
  return (
    <>
      <Header />

      <div className="row">
        <div className="col-md-2">
          <FilterComponent />
        </div>
        <div className="col-md-10">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ProductPage;
