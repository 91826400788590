import logo from "./logo.svg";

import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

import "./App.css";

import React from "react";
import Hero from "./components/Hero";
import CircularMenu from "./components/CircularMenu";
import Landing from "./pages/Landing";
import ProductPage from "./pages/Product";
import ProductComponent from "./components/Product";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="category" element={<ProductPage />}>
            <Route path=":categoryId" element={<ProductComponent />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
