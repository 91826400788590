import React, { useState } from "react";
import "./styles.css";

const LiveChat = () => {
  return (
    <div className="live_chat_section_wrapper">
      <div className="">
        <div className="all_btn">
          <a href="https://wa.me/+658379 0269">
            {" "}
            <i className="fa-brands fa-whatsapp social_chat_btn"></i>
          </a>
          <a href="https://m.me/ReliableEngineeringGlobalPteLtd">
            {" "}
            <i className="fa-brands fa-facebook-messenger social_chat_btn"></i>
          </a>
          <a href="https://ig.me/m/reliable_engineering_globalpte">
            {" "}
            <i className="fa-brands fa-instagram social_chat_btn"></i>
          </a>
          <a href="mailto: sales@reliablepte.com">
            {" "}
            <i className="fa-solid fa-envelope social_chat_btn"></i>
          </a>
        </div>
      </div>

      <br />
    </div>
  );
};

export default LiveChat;
