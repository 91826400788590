import React, { useState, useEffect } from "react";
import "./styles.css";

import axios from "axios";

import { useParams, useLocation } from "react-router-dom";

import SingleHeroProduct from "../SingleHeroProduct";
import BrandMenu from "../BrandMenu";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProductComponent = () => {
  const { categoryId } = useParams();
  const query = useQuery();
  const brandId = query.get("brand_id"); // brandId will be '5'

  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);

  const fetchProducts = async () => {
    try {
      setProducts([]);
      let url;
      if (brandId) {
        url = `https://server.reliablepte.com/api/products?category_id=${categoryId}&brand_id=${brandId}`;
      } else {
        url = `https://server.reliablepte.com/api/products?category_id=${categoryId}`;
      }

      let response = await axios.get(url);

      setProductCount(response.data.count);
      setProducts(response.data.products);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [categoryId, brandId]);

  return (
    <div className="product_container container">
      <BrandMenu categoryId={categoryId} />
      <h6></h6>
      <h6 className="text-end p-2">Total Products: {productCount}</h6>
      <div className="row">
        {products.map((element) => {
          return (
            <div className="col-md-3">
              <SingleHeroProduct
                productId={element.id}
                imageLink={
                  process.env.REACT_APP_GALLERY_URL + "/" + element.image
                }
                title={element.id + " - " + element.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductComponent;
