import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <div className="info_section container p-5">
      <div className="row">
        <div className="col-md-6 address  mt-3">
          <h3 className>Office Address</h3>
          <br />
          <p>
            <span>
              {" "}
              <i class="fa-solid fa-phone text-success"></i>
            </span>{" "}
            +65 8379 0269
          </p>
          <p>
            <span>
              {" "}
              <i class="fa-solid fa-phone text-secondary"></i>
            </span>{" "}
            +65 8141 9831
          </p>
          <p>
            <span>
              <i class="fa-regular fa-envelope text-info"></i>
            </span>
            sales@reliablepte.com
          </p>
          <div>
            <p>
              <span>
                {" "}
                <i class="fa-solid fa-location-dot text-danger"></i>
              </span>
              10 Anson Road, #22-02 International Plaza, Singapore (079903)
            </p>
          </div>
        </div>
        <div className="col-md-6 footer_right_container">
          <div className="domain mt-3">
            <h3>Reliable Engineering Global PTE Ltd.</h3>
          </div>
          <div className="social mt-3">
            <a
              href="https://www.facebook.com/ReliableEngineeringGlobalPteLtd"
              className="fa fa-facebook"
            />
            <a
              href="https://www.linkedin.com/company/reliable-engineering-global-pte-ltd/"
              className="fa fa-linkedin"
            />
            <a href="https://x.com/Reliable_Pte" className="fa fa-twitter" />
            <a
              href="https://www.instagram.com/reliable_engineering_globalpte/"
              className="fa fa-instagram"
            />
            <a
              href="https://www.youtube.com/@Reliable_Pte"
              className="fa fa-youtube"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
