import React from "react";

import "./styles.css";
import StatComponent from "../StatComponent";

const Contact = () => {
  return (
    <div className="container mb-5 contact_section" id="contact">
      <div className="section_title">Contact Us</div>

      <div className="row">
        <div className="col-md-4">
          <StatComponent />
        </div>
        <div className="col-md-8">
          <form className="contact_form">
            <h6 className="text-center px-5">
              If you have any question or you want to request a quote, please
              fill the form below and we will reply you with a custom quote for
              your needs.
            </h6>
            <br />
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter your name"
                aria-describedby="name"
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                aria-describedby="email"
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="message"
                aria-describedby="message"
                placeholder="Type your message here..."
              />
            </div>
            <br />
            <div className="text-center">
              <a href className="btn btn-outline-dark contact_btn">
                Send
              </a>
            </div>
          </form>
        </div>
      </div>

      <br />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.829126875776!2d103.84324972496567!3d1.2758824487119809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da192f8428d57d%3A0xe83688a9109a630a!2sInternational%20Plaza%20Singapore!5e0!3m2!1sen!2sbd!4v1724139330308!5m2!1sen!2sbd"
        style={{ border: 0 }}
        className="map_container"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default Contact;
