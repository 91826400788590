import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import ProductImage from "../../assets/img/product_img.jpg";

const SingleHeroProduct = (props) => {
  return (
    <div className="single_product_card">
      <img src={props.imageLink} className="" />
      <hr className="mx-3" />
      <h6 class="">{props.title}</h6>
      <a href="" className="btn product_card_btn d-block">
        Get a quote
      </a>
    </div>
  );
};

export default SingleHeroProduct;
