import React from "react";

// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/bundle";

import "./styles.css";

import gas_slide from "../../assets/img/collage222.png";
import bms_slide from "../../assets/img/slider/bms_slide.png";
import electrical_safety_slide from "../../assets/img/slider/electrical_safety_banner.png";

const HeroSlider = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
      effect="fade"
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 2000,
        disableOnInteraction: true,
      }}
      pagination={{ clickable: true }}
      navigation={true}
      loop={true}
      className="hero_slider"
      id="gallery"
    >
      <SwiperSlide>
        <div className="single_slide">
          <img
            className="slider_img"
            src="https://www.genesistelesecure.com/images/fire-slider.jpg"
            alt=""
          />
          <h1 className="slider_title">Fire Detection System</h1>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {/* <div className="single_slide">
          <img
            className="slider_img"
            src="https://firepreventionbd.com/wp-content/uploads/2023/08/fire-sprinkler-systems_sm.jpg"
            alt=""
          />
          <h1 className="slider_title">Fire Protection System</h1>
        </div> */}
        <div className="single_slide">
          <img
            className="slider_img"
            src="https://www.honeywellbuildings.in/uploads/banner/1606162613fire_suppression_system_bnr.jpg"
            alt=""
          />
          <h1 className="slider_title">Fire Protection System</h1>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="single_slide">
          <img className="slider_img" src={bms_slide} alt="" />
          <h1 className="slider_title">Building Management System</h1>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="single_slide">
          <img
            className="slider_img"
            src="https://automation.honeywell.com/content/dam/honeywell-edam/sps/common/en-us/homepage/sps-ppe-construction-hero-Image-2880.jpg"
            alt=""
          />
          <h1 className="slider_title">Personal Protective Equipment</h1>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="single_slide">
          <img className="slider_img" src={electrical_safety_slide} alt="" />
          <h1 className="slider_title">Electrical Safety</h1>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="single_slide">
          <img className="slider_img" src={gas_slide} alt="" />
          <h1 className="slider_title">Gas Detection System</h1>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default HeroSlider;
