import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

const SingleServiceCard = (props) => {
  return (
    <div className="service_card">
      <Link to={props.id}>
        <img src={props.img} alt="" />
        <p>{props.title}</p>
        <a href="">Read More</a>
      </Link>
    </div>
  );
};

export default SingleServiceCard;
