import React, { useState } from "react";

import "./styles.css";

import Marquee from "react-fast-marquee";

const BrandSection = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [slideDirection, setSlideDirection] = useState("left");
  const brands = [
    "https://1000logos.net/wp-content/uploads/2017/12/Honeywell-Logo.png",
    "https://www.wss-int.com/wp-content/uploads/Notifier_Banner-web.jpeg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUeL3UhgkIgtfXV2tTygJKHXKcm3jdiFo5jA&s",
    "https://www.honeywellbuildings.in/uploads/fire_safety/category/1605982118morley_hdng.png",

    "https://buildings.honeywell.com/content/dam/hbtbt/en/images/logos/System-Sensor_Logo_Blue_CMYK_HiRes.jpg",

    "https://upload.wikimedia.org/wikipedia/en/8/8c/Honeywell-Gent-Logo.webp",

    "https://tjfire.co.uk/wp-content/uploads/2021/05/ziton-logo.png",

    "https://mircom.com/wp-content/uploads/mircom-header-logo.png",

    "https://lh6.googleusercontent.com/proxy/CZ5dW8jdn9rMF2w9AlcGprXT24lw17yqf23CwduDsUjWkJRbzhAk5tk03SKEqbDDduyAoONyNl9YQw0uFdal1TTLDBW0KcePMYCPC6-ZJRM",

    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkySveKIEnKvhSiQ-mWklJiIgnsCZ4bA5-w&s",

    "https://1000logos.net/wp-content/uploads/2021/11/Siemens-logo.png",

    "https://images.carriercms.com/image/upload/v1682690441/kidde/logos/Web/kidde-logo-170x54.png",

    "https://www.simplexfire.com/-/media/project/jci-global/fire-detection/simplex/united-states-simplex/header-logo/simplex_header3.png?h=44&w=168&la=en&hash=C14A704FBD1E8CD8A3931085E33A7183",

    "https://www.nohmi.co.jp/english/common/images/logo.png",

    "https://www.hochikiamerica.com/medias/logo/logo-medium.png",

    "https://d2c6s77msf04pp.cloudfront.net/uploads/logos/coopers-fire-logo-final-300dpi-620b891d000db.png",

    "https://www.regincontrols.com/globalassets/general-pictures/logotypes/2024_regin_logo_1300x720.jpg?preset=isthv2",

    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Dr%C3%A4ger_Logo.svg/2560px-Dr%C3%A4ger_Logo.svg.png",
    "https://static.vecteezy.com/system/resources/previews/014/414/669/original/3m-logo-on-transparent-background-free-vector.jpg",
    "https://www.karam.in/sites/default/files/karam-logo-01.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Danfoss-Logo.svg/2560px-Danfoss-Logo.svg.png",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYq4nUVRTJZ2QSVAAZ3bDXeDhPIV63QarnbA&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd9hT2i739xzqruYx3wiJHAVlcM0qQAcKIHw&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuKYjr_CoBkmu65ftSmwLMVZ60n9dtxgygYg&s",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Logo_Krohne.svg/2560px-Logo_Krohne.svg.png",
    "https://upload.wikimedia.org/wikipedia/commons/7/76/FlukeLogo.png",
  ];
  return (
    <div className="p-5" id="brands">
      <div className="section_title">Our Brand Offerings</div>

      <div className="text-end">
        <a
          onClick={() =>
            isExpanded ? setIsExpanded(false) : setIsExpanded(true)
          }
          className="btn my-3 toggle_btn"
        >
          Toggle View
        </a>
      </div>

      {isExpanded ? (
        <div className="row">
          {brands.map((i) => (
            <div className="col-md-2">
              <img src={i} alt="" className="logo_list_img" />
            </div>
          ))}
        </div>
      ) : (
        <div className="brand_slider_container">
          <a
            class="fa-solid fa-caret-left"
            onClick={() => setSlideDirection("left")}
          ></a>
          <Marquee
            speed={150}
            gradient={true}
            pauseOnClick={true}
            className="brand_slider"
            direction={slideDirection}
          >
            {brands.map((i) => (
              <img src={i} alt="" className="logo_img" />
            ))}
          </Marquee>
          <a
            class="fa-solid fa-caret-right"
            onClick={() => setSlideDirection("right")}
          ></a>
        </div>
      )}
    </div>
  );
};

export default BrandSection;
