import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import "./styles.css";

const BrandMenu = (props) => {
  const [brands, setBrands] = useState([]);

  const fetchBrands = async () => {
    try {
      setBrands([]);
      let url = `https://server.reliablepte.com/api/brands?category_id=${props.categoryId}`;

      let response = await axios.get(url);

      setBrands(response.data.brands);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, [props.categoryId]);

  return (
    <div class="brand_list_menu">
      {brands.map((element) => {
        return (
          <Link
            to={"?brand_id=" + element.id}
            className="brand_list_item"
            aria-current="true"
          >
            {element.image ? (
              <img
                src={process.env.REACT_APP_GALLERY_URL + "/" + element.image}
                alt=""
              />
            ) : (
              <p>{element.name}</p>
            )}
          </Link>
        );
      })}
    </div>
  );
};

export default BrandMenu;
