import React from "react";

import "./styles.css";

import CountUp from "react-countup";

const StatComponent = () => {
  return (
    <div>
      {/* <CountUp start={0} end={100} duration={1} /> */}

      <div className="stat_container">
        <div className="stat_point">
          <div className="stat_icon">
            <i class="fa-solid fa-fire-extinguisher"></i>
          </div>
          <div className="stat_text">
            <h2>1000+</h2>
            <p>Safety Products</p>
          </div>
        </div>
        <div className="stat_point">
          <div className="stat_icon">
            <i class="fa-solid fa-money-check-dollar"></i>
          </div>
          <div className="stat_text">
            <h2>100%</h2>
            <p>Secure Payment</p>
          </div>
        </div>
        <div className="stat_point">
          <div className="stat_icon">
            <i class="fa-solid fa-user"></i>
          </div>
          <div className="stat_text">
            <h2>100%</h2>
            <p>Customer Satisfaction</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatComponent;
